import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import Banner from '../components/Banner'

import pic01 from '../assets/images/pic01.jpg'
import pic02 from '../assets/images/pic02.jpg'
import pic03 from '../assets/images/pic03.jpg'
import pic04 from '../assets/images/pic04.jpg'
import pic05 from '../assets/images/pic05.jpg'
import pic06 from '../assets/images/pic06.jpg'

class HomeIndex extends React.Component {
  render() {

    return (
      <Layout>
        <Helmet>
          <title>Brisdo Custom Audio</title>
          <meta name="description" content="Company Description" />
        </Helmet>


        <Banner />

        <div id="main">
          <section id="one" className="tiles">
            <article style={{ backgroundImage: `url(${pic01})` }}>
              <header className="major">
                <h3>Ceramic</h3>
                <p>Formed by heat</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic02})` }}>
              <header className="major">
                <h3>Drivers</h3>
                <p>Silky smooth harmonies</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic03})` }}>
              <header className="major">
                <h3>Engineering</h3>
                <p>Deep calculated bass</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            <article style={{ backgroundImage: `url(${pic04})` }}>
              <header className="major">
                <h3>Testing</h3>
                <p>QA and listening</p>
              </header>
              <Link to="/landing" className="link primary"></Link>
            </article>
            {/* <article style={{backgroundImage: `url(${pic05})`}}>
                            <header className="major">
                                <h3>Consequat</h3>
                                <p>Ipsum dolor sit amet</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article>
                        <article style={{backgroundImage: `url(${pic06})`}}>
                            <header className="major">
                                <h3>Etiam</h3>
                                <p>Feugiat amet tempus</p>
                            </header>
                            <Link to="/landing" className="link primary"></Link>
                        </article> */}
          </section>
          <section id="two">
            <div className="inner">
              <header className="major">
                <h2>Contact us</h2>
              </header>
              <p>We're a fun-loving team that works hard and plays hard, yet we're inclusive.  We love sound and music of all kinds and shapes including film, sound effects, hip-hop, rock and roll, jazz and there's even a soft spot for country and green grass.  We'd love to talk to you.</p>
              <ul className="actions">
                <li><Link to="/landing" className="button next">Get Started</Link></li>
              </ul>
            </div>
          </section>
        </div>

      </Layout>
    )
  }
}

export default HomeIndex